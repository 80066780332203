import { http } from './config';

export const userBalanceRequest = () => {
    return http.get('wallet/information')
};

export const fetchUserCards = (type) => {
    return http.get('payment/cards', {
        params: {
            type
        }
    })
};

export const getPaymentConfig = (type) => {
    return http.get(`payment/${type}/configurations`)
};

export const depositRequest = (form) => {
    return http.post('payment', form);
};

export const transactionStatusRequest = (id) => {
    return http.get(`transaction/status/${id}`);
};

export const getPaymentTransaction = (params = {}) => {
    return http.get(`payment/transactions?${params}`);
}

export const withdrawalRequest = (form, token) => {
    return http.post(`wallet/withdrawal-request`, form, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    });
};

export const cancelWithdrawalRequest = (id) => {
    return http.post(`wallet/withdrawal/${id}/cancel`, { id })
};
